import * as React from 'react'
import { Link as RouterLink } from 'gatsby'
import styled from 'styled-components'

import { Layout } from '../layouts/Layout'
import { SEO } from '../components/SEO'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #18a5d6;
  color: #ffffff;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 64px;
  font-family: sofia-pro-condensed, sans-serif;
  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 32px;
`

const Text = styled.p`
  color: #fff;
`

const Link = styled(RouterLink)`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export default function NotFoundPage(): React.ReactElement {
  return (
    <Layout>
      <Wrapper>
        <SEO title="404: Not found" />
        <Title>
          404
          <br />
          Not found
        </Title>
        <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
        <Link to="/">Go back to the homepage</Link>
      </Wrapper>
    </Layout>
  )
}
